import { useEffect, useState, useCallback } from "react";
import Web3 from "web3";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ERC20ABI from "./ABIs/ERC20ABI.json";
import NFTABI from "./ABIs/NFTABI.json";
import stakeABI from "./ABIs/stakeABI.json";

const zoinContractAddress = "0xa122cDb6DaF69AC9021799724aCe84b6842aE853";
const stakeContractAddress = "0xED84985593d84B9e01eC5aB9eff65f6D08446fdb";
const nftContractAddress = "0x7299D951a69f4bBD067c834c7Fccc5c6eE11bf44";

const { ethereum } = window;
const RPC = "https://bsc-dataseed.bnbchain.org";
const CHAIN_ID = 56;

function App() {
  useEffect(() => {
    isConnected()
  }, [])
  async function isConnected() {
    const accounts = await ethereum.request({ method: 'eth_accounts'})
    if (accounts[0]) {
      setConnected(true);
    } else {
      setConnected(false);
  }
  }
  const [web3, setWeb3] = useState(null);
  const [zoinToken, setZoinToken] = useState(null);
  const [staking, setStaking] = useState(null);
  const [account, setAccount] = useState('');
  const [end, setEnd] = useState(0);
  const [claim, setClaim] = useState(false);
  const [nft, setNFT] = useState(false);
  const [nftContract, setNFTContract] = useState(false);
  const [address, setAddress] = useState(false);
  const [searchedAddress, setSearchedAddress] = useState("");
  const [nodesOpened, setNodesOpened] = useState(0);
  const [connected, setConnected] = useState(0);

  async function isValid(data_, to_, value_) {
    try {
      await web3.eth.estimateGas({ from: account, data: data_, to: to_, value: value_ })
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  const error = (message) => {
    toast.error(String(JSON.stringify(message)), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function loadWeb3() {
    const web3 = new Web3(RPC);
    const zoinToken = new web3.eth.Contract(ERC20ABI, zoinContractAddress);
    const staking = new web3.eth.Contract(stakeABI, stakeContractAddress);
    const nft = new web3.eth.Contract(NFTABI, nftContractAddress);
    setWeb3(web3);
    setZoinToken(zoinToken);
    setStaking(staking);
    setNFTContract(nft);
    const nodesOpened = await staking.methods.nodesOpened().call();
    setNodesOpened(nodesOpened);
  }

  const loadBlockchainData = useCallback(async () => {
    loadWeb3();
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        setAccount(accounts[0]);
        return (accounts[0]);
      } catch (err) {
        if (err.code === 4001) {
          error("User denied transaction.");
        } else {
          error("An unknown error occured.")
          console.error(err);
        }
      }
    } else {
      error("MetaMask is not installed.");
      return false;
    }
    isConnected();
  }, [])

  async function openNodeWithNFT() {
    if (typeof window.ethereum !== 'undefined') {
      if (await loadBlockchainData()) {
        try {
          const chainId = await ethereum.request({ method: 'eth_chainId' });
          if (Number(chainId) !== CHAIN_ID) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x' + String(CHAIN_ID.toString(16)) }],
            });
          } else {
            const stakeData = await staking.methods.stakeWithNFT().encodeABI();
            console.log(stakeData);
            if (await isValid(stakeData, stakeContractAddress, (50000000000000000n))) {
              await ethereum.request({ method: 'eth_sendTransaction', params: [{ from: account, to: stakeContractAddress, value: (50000000000000000n).toString(16), data: stakeData }] });
            } else {
              error("An error occured.")
            }
          }
        } catch (err) {
          if (err.code === 4001) {
            error("User denied transaction.");
          } else {
            error("An unknown error occured.")
            console.error(err);
          }
        }
      }

    } else {
      error("MetaMask is not installed.");
    }
  }
  async function approveNFT() {
    if (typeof window.ethereum !== 'undefined') {
      if (await loadBlockchainData()) {
        try {
          const chainId = await ethereum.request({ method: 'eth_chainId' });
          if (Number(chainId) !== CHAIN_ID) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x' + String(CHAIN_ID.toString(16)) }],
            });
          } else {
            const approveNFTData = await nftContract.methods.setApprovalForAll(stakeContractAddress, true).encodeABI();
            if (await isValid(approveNFTData, nftContractAddress, (0n).toString())) {
              await ethereum.request({ method: 'eth_sendTransaction', params: [{ from: account, to: nftContractAddress, value: (0n).toString(16), data: approveNFTData }] });
            } else {
              error("An error occured.")
            }
          }
        } catch (err) {
          if (err.code === 4001) {
            error("User denied transaction.");
          } else {
            error("An unknown error occured.")
            console.error(err);
          }
        }
      }

    } else {
      error("MetaMask is not installed.");
    }
  }

  async function openNode() {
    if (typeof window.ethereum !== 'undefined') {
      if (await loadBlockchainData()) {
        try {
          const chainId = await ethereum.request({ method: 'eth_chainId' });
          if (Number(chainId) !== CHAIN_ID) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x' + String(CHAIN_ID.toString(16)) }],
            });
          } else {
            const stakeData = await staking.methods.stake().encodeABI();
            console.log(stakeData);
            if (await isValid(stakeData, stakeContractAddress, (50000000000000000n).toString(16))) {
              await ethereum.request({ method: 'eth_sendTransaction', params: [{ from: account, to: stakeContractAddress, value: (50000000000000000n).toString(16), data: stakeData }] });
            } else {
              error("An error occured.")
            }
          }
        } catch (err) {
          if (err.code === 4001) {
            error("User denied transaction.");
          } else {
            error("An unknown error occured.")
            console.error(err);
          }
        }
      }

    } else {
      error("MetaMask is not installed.");
    }
  }

  async function claimNode() {
    if (typeof window.ethereum !== 'undefined') {
      if (await loadBlockchainData()) {
        try {
          const chainId = await ethereum.request({ method: 'eth_chainId' });
          if (Number(chainId) !== CHAIN_ID) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x' + String(CHAIN_ID.toString(16)) }],
            });
          } else {
            const claimData = await staking.methods.claim().encodeABI();
            if (await isValid(claimData, stakeContractAddress, (0).toString())) {
              await ethereum.request({ method: 'eth_sendTransaction', params: [{ from: account, to: stakeContractAddress, value: (0).toString(), data: claimData }] });
            } else {
              error("You are ineligible to claim rewards.")
            }
          }
        } catch (err) {
          if (err.code === 4001) {
            error("User denied transaction.");
          } else {
            error("An unknown error occured.")
            console.error(err);
          }
        }
      }
    } else {
      error("MetaMask is not installed.");
    }
  }

  async function getDetails() {
    try {
      const node = await staking.methods.node(address).call();
      setEnd(Number(node.end));
      setClaim(node.claimed);
      setNFT(node.NFT);
      setSearchedAddress(address);

    } catch (err) {
      if (err.code === 1100) {
        error("The address provided is invalid.");
      } else {
        error("An unknown error occured.")
        console.error(err);
      }
    }
  }

  async function approveZoin() {
    if (typeof window.ethereum !== 'undefined') {
      if (await loadBlockchainData()) {
        try {
          const chainId = await ethereum.request({ method: 'eth_chainId' });
          if (Number(chainId) !== CHAIN_ID) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x' + String(CHAIN_ID.toString(16)) }],
            });
          } else {
            const approveData = await zoinToken.methods.approve(stakeContractAddress, 5000000000000000000000n).encodeABI();
            if (await isValid(approveData, zoinContractAddress, (0).toString())) {
              await ethereum.request({ method: 'eth_sendTransaction', params: [{ from: account, to: zoinContractAddress, value: 0, data: approveData }] });
            } else {
              console.log('a')
              error("An error occured.")
            }
          }
        } catch (err) {
          if (err.code === 4001) {
            error("User denied transaction.");
          } else {
            error("An unknown error occured.")
            console.error(err);
          }
        }
      }
    }
  }

  const updateAddress = (e) => setAddress(e.target.value);

  if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload();
    })
  
    window.ethereum.on('accountsChanged', async function (accounts) {
      setAccount(accounts[0]);
      isConnected();
    })
  } else {
    error("No web3 wallet found")
  }

  return (
    <div>
      <div className="box">
        <h1>ZOIN Nodes</h1>
        <p>ZOIN Nodes is a simple staking platform that allows you to open a Node for 0.05 BNB and 5000 ZOIN, wait 30 days, and receive 6000 ZOIN which is 240% APY. If you stake with a Zoin NFT, a 10% bonus (100 ZOIN) is added!</p>
        <button onClick={() => loadBlockchainData()} className="button_white">{connected ? "Connected" : "Connect"}</button><br/>
        <button onClick={() => approveZoin()} className="button">Approve Zoin</button>
        <button onClick={() => approveNFT()} className="button">Approve NFT</button>
        <button onClick={() => openNode()} className="button">Open Node</button>
        <button onClick={() => openNodeWithNFT()} className="button">Open Node with NFT</button>
        <button onClick={() => claimNode()} className="button">Claim Rewards</button>

        <b><p>ZOIN Node Explorer</p></b>
        <p>Enter addresses and to get data pulled directly from the blockchain regarding their ZOIN Node status.</p>
        <div>
          <input placeholder="0x0000000000000000000000000000000000000000" className="address-input" onChange={updateAddress}></input>
          <button className="button2" onClick={() => getDetails()}>↩</button>
        </div>
        <div className="table">
          <div className="table-row">
            <div className="table-cell">Address</div>
            <div className="table-cell">{!searchedAddress ? "N/A" : searchedAddress.slice(0, 5) + "..." + searchedAddress.slice(-4)}</div>
          </div>
          <div className="table-row">
            <div className="table-cell">End</div>
            <div className="table-cell">{end === 0 ? "N/A" : (new Date(end * 1000)).toLocaleDateString() + ' ' + (new Date(end * 1000)).toLocaleTimeString()}</div>
          </div>
          <div className="table-row">
            <div className="table-cell">Claimed</div>
            <div className="table-cell">{end === 0 ? "N/A" : String(claim).charAt(0).toUpperCase() + String(claim).slice(1)}</div>
          </div>
          <div className="table-row">
            <div className="table-cell">NFT</div>
            <div className="table-cell">{end === 0 ? "N/A" : String(nft).charAt(0).toUpperCase() + String(nft).slice(1)}</div>
          </div>
        </div>
        <div className="table">
          <div className="table-row">
            <div className="table-cell">Nodes Opened</div>
            <div className="table-cell">{nodesOpened + "/100"}</div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className="metamask-badge">
        <a href="https://metamask.io/" target="_blank" rel="noreferrer">
          <img alt="" className="metamask-logo" src="assets/metamask.webp"></img>
          <p className="metamask-text">Metamask Compatible</p>
        </a>
      </div>
    </div>
  );
}

export default App;
